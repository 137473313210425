.profile-sidebarEdu {
    margin-top: -10px;
    max-width: 850px; /* Increased from 400px to 600px */
    width: 100%;
    margin-right: 0;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.map-slideshow {
    width: 100%;
    height: 100%; /* Adjusted height */
    position: relative;
    overflow: hidden;
}

.map-slideshow img {
    width: 850px;
    object-fit: cover;
    height: 550px;
}
.profile-image-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-image {
    width: 100%;
    object-fit: cover;
    height: 300px;
}

.profile-info {
    padding: 0px 15px;
    border-top: 1px solid #e0e0e0;
}
.profile-name {
    font-family: 'Open Sans', sans-serif;
    margin-top: 16px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 450 ;
    font-size: 31px;
    color: #202124;
}
.contact-link {
    color: #0044cc; /* Dark blue color */
    text-decoration: none; /* Removes underline */
}

.contact-link:hover {
    text-decoration: underline; /* Adds underline on hover */
    color: #003399; /* Slightly darker blue on hover */
}
.profile-detail {
    padding: 2px 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #5f6368;
    position: relative;
}

.profile-detail:after {
    content: '';
    display: block;
    height: 1px;
    background-color: #e0e0e0;
    width: calc(100% + 30px);
    position: absolute;
    top: 100%;
    margin-top: 14px;
    left: -15px;
}

.profile-details {
    padding-bottom: 16px;
    padding-top: 10px;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.profile-details p strong {
    font-family: 'Roboto', sans-serif;
    font-weight: 550;
}


.profile-details p {
    margin: 8px 0;
}
.profile-details a {
    color: #1a0dab;
    text-decoration: none;
}

.profile-details a:hover {
    text-decoration: underline;
}
.profile-details p {
    margin-bottom: 4px;
}

.profile-related {
    padding: 20px 15px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
}

 .profile-related h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #000000;
    margin: 0;
    line-height: 1.5;
     font-weight: 500;
}

.search-related-title {
    margin-bottom: 8px;
}

.search-related a:first-child {
    margin-top: 0;
}

.related-people {
    display: flex;
    gap: 20px;
    margin-left: 14px;
    margin-top: 15px;

}

.related-person {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.related-person img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10%;
    margin-bottom: 5px;
}
.related-person span {
    font-size: 12px;
    display: block;
    padding-right: 32px;
}

@media (max-width: 768px) {
    .profile-sidebar {
        margin-top: 10px;
        margin-right: auto;
        margin-left: auto;

    }

}

