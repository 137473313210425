.gold-star {
    color: #ffa600;
    font-size: 12px;
}

.grey-star {
    color: grey;
    font-size: 12px;
}

.star-rating {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 5px;
}

.reviews-count {
    margin-top: 4px;
    margin-left: 5px;
    color: grey;
}

.extra-skills-content {
    /* Remove or adjust these margins */
    margin-left: 232px;
    margin-top: -44px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}
@media (max-width: 1247px) {
    .extra-skills-content {
        margin-left: 180px;
    }
}
/* Decrease padding at 1200px */
@media (max-width: 1200px) {
    .extra-skills-content {
        margin-left: 180px;
    }

}

/* Further decrease padding at 1150px */
@media (max-width: 1150px) {
    .extra-skills-content {
        margin-left: 130px;
    }

}