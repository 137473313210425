.resume {
    display: flex;
    flex-direction: column;
}
.resume-content {

    margin-right: 20px;
}
.main-content-container {
    min-width: 1028px;
    flex-direction: row;
    flex-wrap: nowrap;

}

.resume-content-with-sidebar {
    display: flex;
    /* It's important to align items to the start to prevent stretching */
    align-items: flex-start;
    /*justify-content: space-between;*/
    padding: 30px 0 30px 232px;
    gap: 0px;
    min-width: 1028px;
    flex-wrap: nowrap;
    overflow-x: auto;

}

.resume-item {
    margin-bottom: 20px; /* Space between items */
}

.resume-stats {
    color: #70757a;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 12px;
    padding-left: 1px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
}

.resume-item-title {
    color: #1a0dab;
    font-size: 19px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 2px; /* Increased space between title and path */
}

.resume-item-title:hover
{
    text-decoration:underline;
}

.resume-item-path {
    color: #006621;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 2px; /* Increased space between path and description */
}
.path-part {
    color: #006621;
    text-decoration: none;
    cursor: pointer;
}

.path-part:hover {
    text-decoration: underline;
}

.resume-item-description {
    color: #545454;
    font-size: 16px;
    line-height: 1.3;
    font-family: 'Roboto', sans-serif;
    max-width: 680px;
    padding-right: 46px;
    word-break: break-word;

}

/*Search related*/

.extra-content {
    margin-left: 232px;
    margin-top: -104px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}
.search-related {
    color: #70757a;
    font-size: 19px;
    font-family: 'Roboto', sans-serif;

}
.search-related a:hover {
    text-decoration: underline;
}
.search-related a {
    display: block;
    color: #1a0dab;
    font-size: 16px;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    margin-bottom: -25px;
}

.hire-me-title {
    font-family: 'Roboto', sans- serif;
    font-size: 26px;
    font-weight: 400;
    margin-bottom: -5px;
}

.hire-me-section {
    background-color: #ffffff;
    padding: 18px;
    text-align: left;
    margin-top: 30px ;
    margin-bottom: 60px;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    max-width: 624px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hire-me-button {
    background-color: #4285f4;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: -5px;
    align-self: flex-start;
}

.hire-me-button:hover {
    background-color: #357ae8;
}
.book-item {
    display: block;
    margin-bottom: 15px; /* Reduced space between items */
}

.book-title {
    color: #1a0dab;
    font-size: 19px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    margin-bottom: 5px; /* Reduced space between title and image */
}

.book-title:hover {
    text-decoration: underline;
}

.book-details-container {
    display: flex;
    align-items: flex-start;
}

.book-image {
    width: 67.6px; /* 1.3x larger */
    height: 104px; /* 1.3x larger */
    object-fit: cover;
    cursor: pointer;
    margin-right: 8px; /* Reduced space between image and text */
}

.book-text-details {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the text details */
    gap: 1px; /* Reduced space between text lines */
}

.book-path{
    margin-top: -5px;
    color: #006621;
}
.book-author-year{
    color: #545454; /* Same color as description */
 }

.book-path,
.book-author-year {

    font-size: 17px; /* 1px larger */
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    margin-bottom: 1px; /* Reduced space between path and author-year */
    text-decoration: none;
}

.book-path:hover,
.book-author-year:hover {
    text-decoration: underline;
}

.book-description {
    color: #545454;
    font-size: 16px;
    line-height: 1.25;
    font-family: 'Roboto', sans-serif;
    word-break: break-word; /* Ensures words break to prevent horizontal overflow */
    overflow-wrap: break-word; /* Alternative to word-break for handling overflow */
    max-width: 680px; /* Or adjust based on your layout's needs */
}

/* You can add additional media queries here to adjust the layout on smaller screens. */


@media (max-width: 1247px) {
    .resume-content-with-sidebar {
        padding-left: 180px;
        padding-bottom: 60px;
    }
    .extra-content {
        margin-left: 180px;
    }

}
/* Decrease padding at 1200px */
@media (max-width: 1200px) {
    .resume-content-with-sidebar {
        padding-left: 180px;
        padding-bottom: 60px;
    }
    .extra-content {
        margin-left: 180px;
    }

}

/* Further decrease padding at 1150px */
@media (max-width: 1150px) {
    .resume-content-with-sidebar {
        padding-left: 130px;
        padding-bottom: 60px;
    }
    .extra-content {
        margin-left: 130px;
    }

}

/*!* And so on, until we reach 1028px *!*/
/*@media (max-width: 1100px) {*/
/*    .resume-content-with-sidebar {*/
/*        padding-left: 80px;*/
/*        padding-bottom: 60px;*/
/*    }*/
/*    .extra-content {*/
/*        margin-left: 80px;*/
/*    }*/

/*}*/

/*@media (max-width: 1050px) {*/
/*    .resume-content-with-sidebar {*/
/*        padding-left: 40px;*/
/*        padding-bottom: 60px;*/
/*    }*/
/*    .extra-content {*/
/*        margin-left: 40px;*/
/*    }*/

/*}*/

/*!* Ensure padding is 10px for widths 1028px and below *!*/
/*@media (max-width: 1028px) {*/
/*    .resume-content-with-sidebar {*/
/*        padding-left: 10px;*/
/*        padding-bottom: 80px;*/
/*    }*/
/*    .extra-content {*/
/*        margin-left: 10px;*/
/*    }*/
/*}*/
/*@media (max-width: 768px) {*/
/*    .resume-content-with-sidebar {*/
/*        !* Keep it in a row format and prevent wrapping *!*/
/*        min-width: 1028px;*/
/*        flex-wrap: nowrap;*/
/*        padding-left: 10px; !* Adjust padding for smaller screens *!*/

/*    }*/

/*    .resume-content {*/
/*        !* Adjust basis and grow as needed *!*/
/*        flex-basis: auto;*/

/*    }*/

/*    .extra-content {*/

/*        padding-left: 2px;*/
/*        margin-top: -155px;*/
/*        font-size: 16px;*/
/*        font-family: 'Roboto', sans-serif;*/

/*    }*/
/*}*/

