/* Contact.css */
.contact-page {
    
}

.contact-main-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 1028px;
    /*overflow-x: auto; !* Only horizontal scroll at the bottom *!*/
    padding: 0px 0; /* Padding for scrollbar */
}
   /*name email itd*/
.contact-container {
    width: calc(100% - 442px); /* Adjust width */
    max-width: 630px;
    margin-bottom: 20px;
    margin-right: 20px; /* Right margin to maintain gap */
    flex-shrink: 0;
}

.contact-content-with-sidebar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 0;
    max-width: 1050px;
    margin-left: 229px;
    flex: 1 0 auto;
    gap: 20px;
}

.contact-header {
    background-color: transparent;
    padding: 4px 15px;
    margin-bottom: 20px;
    margin-right: 1px;
    border: 1px solid #dfe1e5;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.contact-header h1 {
    font-size: 28px;
    margin-top: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 350;
    color: black;
}

.contact-subtitle {
    font-size: 17px;
    margin-top: -10px;
    font-weight: 300;
    color: #8f8f8f;
}
.contact-form {
    padding-right: 20px; /* Add padding to maintain gap */
}

.contact-form label {
    display: block;
    margin-bottom: 0.5em;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    max-width: 600px; /* Max width for input fields */
    padding: 10px;
    margin-bottom: 1em;
    border: 1px solid #dfe1e5;
}

.contact-form textarea {
    height: 250px;
    resize: vertical;
}

.required {
    color: red;
}

.form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

}

.submit-button {
    background-color: #4285f4;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    max-width: 150px;
    margin-right: 15px;
    transition: background-color 0.3s, transform 0.2s;
}
.submit-button:hover {
    background-color: #357ae8;
}


.submit-button:active {
    transform: scale(0.98);
}


/* Update styles for the privacy policy link to be more specific */


.privacy-policy-link {
    color: #4285f4;
    cursor: pointer;
    text-decoration: underline;
}
.privacy-statement {
    color: #8f8f8f;
    font-size: 14px;
    padding-left: 10px;
    margin: 0;
    flex-grow: 1;
}

/* Popup styling */


.privacy-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Above other content but below the close button */
    background: rgba(0, 0, 0, 0.5);
}
.privacy-container.active {
    display: flex;
}

/* Popup styling */
.privacy-popup {
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    background: white;
    padding: 20px;
    margin: auto;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative; /* Relative to the container */
}

.privacy-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 999;
}
.privacy-backdrop.active {
    display: block;
}
.privacy-popup.active {
    display: block;
}

.close-button {
    position: fixed;
    margin-right: -800px;
    margin-top: -950px;
    background: none;
    border: none;
    border-radius: 50%;
    color: white;
    padding: 0;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1002;
    align-items: center;
    justify-content: center;
}
.close-icon {
    font-size: 36px;
}

.close-button:hover {
    color: grey;
}

                /* error/success styling */

.status-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 1000;
    justify-content: center;
    align-items: center;
}
.status-backdrop.active {
    display: flex;
}

.status-popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.2);
    max-width: 400px;
    text-align: center;
    position: relative;
}

.status-popup h2 {
    font-size: 20px;
    font-weight: bold;
    color: #db4437;
    margin-bottom: 15px;
}
.status-popup .success-message  {
    color: #0f9d58;
}

.status-popup p {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin-bottom: 20px;
}
.status-popup a {
    color: #4285f4;
    text-decoration: none;
}
.status-popup a:hover {
    text-decoration: underline;
}
.status-close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #333;
    font-size: 24px;
}

.status-close-button:hover {
    color: #666;
}

                /*  error/success styling  */

     /*scaling*/

@media (min-width: 1921px) {
    .close-button {
        margin-right: -800px;
        margin-top: -1150px;

    }
}

@media (max-width: 1920px) {
    .close-button {
        margin-right: -700px;
        margin-top: -970px;

    }
}
@media (min-height: 970px) {
    .close-button {
        margin-right: -700px;
        margin-top: -970px;


    }
}
@media (max-height: 969px) {
    .close-button {
        margin-right: -700px;
        margin-top: -780px;

    }
}
@media (max-height: 710px) {
    .close-button {
        margin-right: -700px;
        margin-top: -500px;

    }
}
@media (max-height: 310px) {
    .close-button {
        margin-right: -700px;
        margin-top: -100px;

    }
}
@media (max-width: 1366px) {
    .close-button {
        margin-right: -750px;
        margin-top: -850px;
    }
}
@media (max-width: 1000px) {
    .close-button {
        margin-right: -700px;
        margin-top: -650px;
    }
}


