.profile-sidebar-contact {
    position: relative;
    margin-top: 0px;
    max-width: 400px;
    width: 100%;
    margin-right: 0;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-x: hidden; /* To ensure the image covers the area without overflow */
    flex: 0 0 400px;

}
.contact-profile-image-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center; /* centers the image vertically */
    justify-content: center; /* centers the image horizontally */
}

.contact-profile-image {
    width: 100%;
    object-fit: cover;
    height: 300px;
}

.contact-profile-info {
    padding: 0px 15px;
    border-top: 1px solid #e0e0e0;
}
.contact-profile-name {
    font-family: 'Open Sans', sans-serif;
    margin-top: 16px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 450 ;
    font-size: 31px;
    color: #202124;
}
.contact-profile-detail {
    padding: 1px 2px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #5f6368;
    position: relative;
}

.contact-profile-detail:after {
    content: '';
    display: block;
    height: 1px;
    background-color: #e0e0e0;
    width: calc(100% + 30px);
    position: absolute;
    top: 100%;
    margin-top: 14px;
    left: -15px;
}

.contact-profile-details {
    padding-bottom: 0px;
    padding-left: 2px;
    padding-top: 4px;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.contact-profile-details p strong {
    font-family: 'Roboto', sans-serif;
    font-weight: 550;
}

.contact-profile-details p {

    margin: 16px 0;
}
.contact-profile-details a {
    color: #1a0dab;
    line-height: 1.4;
    text-decoration: none;
}

.contact-profile-details a:hover {
    text-decoration: underline;
}

.contact-social-media-section {
    margin-top: -10px;
    padding: 0px 16px;

    position: relative;
}
.contact-social-media-title {
    margin-top: 10px;
    margin-bottom: 6px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 450 ;
    font-size: 31px;
}

.social-media-link {
    color: #1a0dab;
    text-decoration: none;
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    display: block; /* Each link on its own line */
    padding: 14px 0;
    position: relative;

    margin-right: -15px;
    width: calc(100% + 30px);
}

.social-media-link:after {
    content: '';
    display: block;
    height: 1px;
    background-color: #e0e0e0;
    width: calc(100% + 30px);
    position: absolute;
    bottom: 0;
    left: -15px;
}

.social-media-link:first-child:before {
    content: '';
    display: block;
    height: 1px;
    background-color: #e0e0e0;
    width: calc(100% + 30px);
    position: absolute;
    top: 0;
    left: -15px;
}

.social-media-link:last-child:after {
    display: none;
}
