@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');

.header {
    width: 100%;
    min-width: 1279px;
}

.header-top {
    background-color: #f1f1f3;
    display: flex;
    align-items: center;
    padding: 9px 80px;
    border-bottom: 1px solid #e0e0e0;
}

.header-logo {
    height: 35px;
    margin-right: 28px;
}

.header-search-bar {
    display: flex;
    align-items: center;
    width: 44%;
    max-width: 630px;
    min-width: 550px;
    margin-left: 9px;
}

.header-search-input {
    width: 100%;
    padding: 10px 8px;
    border: 1px solid #dfe1e5;
    border-right: none;
    border-radius: 0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFFFFF;
    cursor: text;
}


.header-navigation-bar {
    display: flex;
    justify-content: flex-start;
    background-color: #FFFFFF;
    border-bottom: 1px solid #e0e0e0;
    padding-left: 230px;
}

.nav-item {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    margin: 0 14px;
    color: #7e7e7e;
    cursor: pointer;
    position: relative;

}
.nav-item a {
    display: inline-block;
    text-decoration: none;
    color: #7e7e7e;
    transition: transform 0.3s ease, color 0.3s ease;
    padding-top: 22px;
    padding-bottom: 22px;
    width: 100%;
}
.nav-item.active a , .nav-item.active:hover a {
    color: #4285f4;
}
.nav-item:hover a {
    transform: scale(1.05);
    color: #4285f4;
}
.header-navigation-bar:hover .nav-item.active a {
    color: #7e7e7e;
}

.nav-item::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 3px;
    background-color: transparent;
    width: 0;
    left: 0;
    right: 0;
    margin: auto; /* Center the underline */
    transition: width 0.3s ease, background-color 0.3s ease;
}

.nav-item.active a{
    color: #4285f4;
}
.header-navigation-bar:hover .nav-item.active::after {
    width: 0; /* Removes underline from active item when another item is hovered */
}
.nav-item.active::after {
    width: 150%;
    left: -24%;
    background-color: #4285f4;
}
.nav-item:not(.active):hover a {
    color: #4285f4;
    transform: scale(1.05);
}
.nav-item:not(.active):hover::after {
    width: 150%;
    left: -24%;
    background-color: #4285f4;
}

.header-search-button {
    outline: none;
    background-color: #4285f4;
    color: #ffffff;
    padding: 9px 19px;
    border: none;
    border-radius: 0;
    margin-left: -16px;
    height: 41px;
    width: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header-search-button .fa-magnifying-glass {
    font-size: 16px;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

@media (max-width: 1247px) {
    .header-top {
        padding-left: 70px;
    }
    .header-navigation-bar {
        padding-left: 212px;
    }
    .header {
        width: 100%;
    }

}
/* Decrease padding at 1200px */
@media (max-width: 1200px) {
    .header-top {
        padding-left: 60px;
    }
    .header-navigation-bar {
        padding-left: 202px;
    }

}

/* Further decrease padding at 1150px */
@media (max-width: 1150px) {
    .header-top {
        padding-left: 40px;
    }
    .header-navigation-bar {
        padding-left: 182px;
    }

}

/* And so on, until we reach 1028px */
@media (max-width: 1100px) {
    .header-top {
        padding-left: 30px;
    }
    .header-navigation-bar {
        padding-left: 172px;
    }

}

@media (max-width: 1050px) {
    .header-top {
        padding-left: 20px;
    }
    .header-navigation-bar {
        padding-left: 162px;
    }

}

/* Ensure padding is 10px for widths 1028px and below */
@media (max-width: 1028px) {
    .header-top {
        padding-left: 20px;
    }
    .header-navigation-bar {
        padding-left: 162px;
    }
}
