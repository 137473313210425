
.hire-me-title-edu {
    font-family: 'Roboto', sans- serif;
    font-size: 26px;
    font-weight: 400;
    margin-bottom: -5px;
}

.hire-me-section-edu {
    background-color: #ffffff;
    padding: 18px;
    text-align: left;
    margin-top: 10px ;
    margin-bottom: 26px;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    max-width: 624px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hire-me-subtitle-edu{
    font-family: 'Roboto', sans- serif;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 300;
    line-height: 1.4;

}

.hire-me-button-edu {
    background-color: #4285f4;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 0px;
    align-self: flex-start;
}

.hire-me-button-edu:hover {
    background-color: #357ae8;
}