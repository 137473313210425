@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');

.App {
  text-align: center;
  min-width: 1028px;  /* Ensuring minimum width */
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Ensure vertical scrolling is also available */
}

.App-header {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: calc(22.5% - 290px); /* Adjusted padding to move content up */
  font-size: calc(12px + 2.4vmin);
  color: #282c34;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .App-header {
    padding-top: calc(37.5% - 156px);
  }
}

.home-search-bar {
  width: 600px;
  height: 39px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #4676d7;
  margin: 0 auto 18px;
  overflow: hidden; /* Hide overflow */
  white-space: nowrap; /* Prevent text wrapping */
}
.type-animation-wrapper {
  font-family: 'Roboto Light', sans-serif;
  font-size: 18px;
  padding-left: 7px;
  padding-right: 7px;
  color: rgba(0, 0, 0, .87);
  display: inline-block;
  white-space: nowrap;
  transform: translateX(0);
  transition: transform 0.5s linear;
}
.search-bar input::placeholder {
  color: #9aa0a6;
}


.home-logo {
  width: 342px;
  margin-bottom: 27px;
}

.buttons button {
  background-color: #f3f2f2;
  border: 1px solid #f8f9fa;
  color: #5f6368;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  margin: 16px 2px;
  padding: 9px 21px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s, box-shadow 0.2s, border-color 0.2s;
}

.buttons button:hover {
  border-color: #c6c6c6;
  box-shadow: 0 1.5px 3px rgba(0,0,0,0.2);
  background-color: #ebebeb;
}

.buttons button:active {
  background-color: #f0f1f2;
  box-shadow: none;
}